body {
  font-family: Roboto, sans-serif;
  background: #f2f2f2;
  color: #757575;
  margin: 0px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}


.buttons {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  /* height: 75vh */
}

.button {
  margin: 0 20vw
}

.images {
  display: flex;
  justify-content: center;
}

.delete {
  position: relative;
  top: 16px;
  right: 15px
}

footer svg,
.button svg,
.delete svg {
  filter: drop-shadow(3px 3px 3px #999);
  transition: 0.3s;
  transform: rotate(-0.2deg)
}

footer svg:hover,
.button svg:hover,
.delete svg:hover {
  filter: drop-shadow(4px 4px 4px #999);
  transform: rotate(-0.6deg);
  cursor: pointer
}

.delete svg {
  filter: drop-shadow(1px 1px 1px #999)
}

footer svg {
  filter: drop-shadow(1.5px 1px 1px #999)
}

footer svg:hover,
.delete svg:hover {
  filter: drop-shadow(1.5px 1.5px 1.5px)
}

input[type=file] {
  visibility: hidden;
  position: absolute
}

img {
  max-height: 420px;
  max-width: 420px;
  padding: 10px;
  border: 1px dashed #999;
}

/* Loading */
.loading-wrapper,
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loading {
  width: 200px;
  height: 200px;
}

.loading .background {
  border-radius: 50%;
  background: #6762a6;
  border: 3px solid #c9c3e6;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.25);
  width: 200px;
  height: 200px;
  box-sizing: border-box;
  animation: pulse-colors 2s infinite alternate linear;
}

.loading i {
  margin: 25px 5px 5px 55px;
  float: left;
  font-size: 10em !important;
  color: #fff;
  animation: pulse-icon 4s infinite alternate linear;
}

#notification-wrapper {
  position: absolute;
}

/* Footer */
footer {
  display: flex;
  justify-content: space-between;
  width: 96vw;
  margin: 0 2vw;
}

footer .small-button {
  border-radius: 50%;
  border: 1px solid #fff;
  width: 3.2em;
  height: 3.2em;
  padding: 5px;
  transition: .3s;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}

footer .small-button:hover {
  background: #767676;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.45);
}

footer .small-button.github {
  background: #6e5494;
}

footer .small-button.medium {
  background: #00ab6c;
  font-size: 0.85em;
  padding: 0.65em;
}

@keyframes pulse-colors {
  from {
    background: #c9c3e6;
    border: 3px solid #a29ccc;
  }

  to {
    background: #6762a6;
    border: 3px solid #c9c3e6;
  }
}

/* Animations */
.fadein {
  animation: fadein 2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.spinner {
  animation: spin 1.2s infinite linear;
}

.spinner svg {
  background: #f2f2f2;
  border-radius: 50%
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg)
  }

  100% {
    -webkit-transform: rotate(360deg)
  }
}

.rolling {
  position: absolute;
  animation: move 6s ease-out;
  filter: drop-shadow(4px 4px 4px #999);
}

@-webkit-keyframes move {
  0% {
    left: -5%;
  }

  100% {
    left: 105%;
  }
}

@keyframes pulse-icon {
  from {
    text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.25);
  }

  to {
    text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.55);
  }
}









.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #f2f2f2;
  background-image: url('../image_of_sky.jpg');
  background-size: cover; /* To cover the entire viewport */
  background-repeat: no-repeat; /* To prevent the image from repeating */
  background-position: center center; /* To center the image */  
  color: black;
  font-family: 'Poppins', sans-serif;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);  */
}

.logo {
  display: flex;
  align-items: center;
  font-size: 32px;
  font-weight: bold;
  gap: 10px;
}



.menu ul {
  list-style: none;
  display: flex;
  gap: 20px;
}

.menu li a {
  text-decoration: none;
  color: black;
  /* Change link color to black */
  font-weight: 500;
  /* Apply a medium font weight */
  transition: color 0.3s ease;
}

.menu li a:hover {
  color: #6d84b4;
}

/* Add these styles to your App.css */
.upload-text {
  text-align: center;
  margin-top: 20px;
}

.upload-text p {
  font-size: 18px;
  font-weight: bold;
}

.other-text {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  gap: 10px;
  padding: 20px;
}

/* Add these styles to your CSS
.map-container {
  height: 300px;
} */

.leaflet-container {
  width: 100wh;
  height: 50vh;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%; /* Or specify a specific width if you prefer */
}

.image-list {
  text-align: center;
}


.fov-map {
  align-self: center;
  width: 60%;
  height: 400px; /* Adjust the height value as needed */
}
